import '@styles/globals.css';
import '@styles/hamburguer-menu.css';
import type { AppProps } from 'next/app';
import { Layout } from '@components/Layout';
import '@lib/firebase/firebase';
import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { PARENT_URL } from '@lib/constants';
import GlobalStoreProvider from '@providers/GlobalStoreProvider';
import AuthProvider from '@providers/AuthProvider';
import LanguageProvider from '@providers/LanguageProvider';

const HEIGHT_OFFSET = 300;
const CHECK_WINDOW_SIZE_PERIOD = 500;

const requestParentResize = (clientHeight: number, parentUrl: string): void => {
    console.info('Request resize of parent', { clientHeight });

    return window.parent.postMessage(
        {
            func: 'scrollTop',
            message: clientHeight,
        },
        parentUrl,
    );
};

const App = ({ Component, pageProps }: AppProps) => {
    const mainRef = useRef<HTMLDivElement>(null);
    const [clientHeight, setClientHeight] = useState(0);
    const { query } = useRouter();
    const parentUrl = (query[PARENT_URL] as string)?.includes('http')
        ? (query[PARENT_URL] as string)
        : undefined;

    useEffect(() => {
        if (!parentUrl) {
            return undefined;
        }

        const checkWindowSizeInterval = setInterval(() => {
            const currentClientHeight =
                mainRef.current && mainRef.current.clientHeight + HEIGHT_OFFSET;

            if (!currentClientHeight || currentClientHeight === clientHeight) {
                return;
            }

            setClientHeight(currentClientHeight);
        }, CHECK_WINDOW_SIZE_PERIOD);

        return () => clearInterval(checkWindowSizeInterval);
    }, [clientHeight, parentUrl]);

    useEffect(() => {
        if (process.browser && parentUrl) {
            requestParentResize(clientHeight, parentUrl);
        }
    }, [clientHeight, parentUrl]);

    return (
        <main ref={mainRef}>
            <LanguageProvider>
                <AuthProvider>
                    <GlobalStoreProvider>
                        <Layout>
                            <Component {...pageProps} />
                        </Layout>
                    </GlobalStoreProvider>
                </AuthProvider>
            </LanguageProvider>
        </main>
    );
};

export default App;
