//  TODO:  add Swagger/Open API documentation

import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { LearningContentWithId } from '@lib/types';
import { firebaseApp } from '@lib/firebase/firebase';
import { doc, getDoc } from '@firebase/firestore';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LearningContentPath } from '@digico/common/lib/_paths';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SubLearningContent } from '@digico/common/lib/LearningContent';

const db = getFirestore(firebaseApp);

const Params = (method: string, data?: object) => {
    const params: { method: string; headers?: any; body?: string } = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    };
    if (data) params.body = JSON.stringify(data);
    return params;
};

export async function getCountries(language: string) {
    const response = await fetch(
        `/data/countries/${language.toLowerCase()}.json`,
        Params('GET'),
    );
    return response.json();
}

export async function getAllCourses(
    language: string,
): Promise<LearningContentWithId[]> {
    const docRefs = await getDocs(collection(db, LearningContentPath));
    const res: LearningContentWithId[] = [];

    docRefs.forEach((course) => {
        res.push({
            id: course.id,
            ...course.data(),
        });
    });
    res.forEach((course) => {
        getDoc(
            doc(db, `${LearningContentPath}/${course.id}/locales/${language}`),
        ).then((translation) => {
            if (translation && translation.exists() && translation.data()) {
                const translationData: SubLearningContent = translation.data();
                course.title = translationData.title;
                course.text = translationData.text;
            }
        });
    });
    return res;
}

export async function getData(endpoint: string, method: string, data?: object) {
    try {
        const response = await fetch(endpoint, Params(method, data));
        const json = response.json();
        return await Promise.resolve(json);
    } catch (err) {
        return Promise.reject();
    }
}
