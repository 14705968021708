import { Competency } from '@lib/types';

/**
 * Grabs the percentages from a given competency and resolves them into a qualitative result
 *
 * @param {Object<any>} competencies The list of competencies
 * @returns {Number} The qualitative score
 */
export const buildAreaPercentage = (competencies: Competency[]) => {
    const percentages = competencies.map(
        (item: Competency) => item.scorePercentage,
    );

    return (
        percentages.reduce(
            (partial_sum: number, a: number) => partial_sum + a,
            0,
        ) / percentages.length
    );
};
