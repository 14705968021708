import React from 'react';
import { Image } from '@components/Image';
import { LoaderIcon } from '@components/Loader/Loader';
import { useLanguageProvider } from '@providers/LanguageProvider';
import { useDynamicPagesContent } from '@lib/hooks/useDynamicPagesContent';
import type { NavigationItems } from '@digico/common/lib/NavigationItems';
import { useIsLoading } from '@lib/hooks/useIsLoading';
import { Navigation } from '@components/Navigation';

export const LandingHeader = () => {
    const { countryCode } = useLanguageProvider();
    const [navigationItemsContent, isLoadingNavigationItemsContent] =
        useDynamicPagesContent<NavigationItems>(countryCode, 'navigationItems');
    const isLoading = useIsLoading([isLoadingNavigationItemsContent]);

    if (isLoading) return <LoaderIcon />;
    return (
        <header className="tw-mx-8 tw-my-8 tw-flex tw-items-center tw-justify-between lg:tw-mt-4">
            <Image
                src="/images/skillify.png"
                alt="Skillify Logo"
                width={170}
                height={66}
            />
            <Navigation navigationItemsContent={navigationItemsContent} />
        </header>
    );
};
