import React, { useCallback, useEffect, useRef, useState } from 'react';
import Router, { useRouter } from 'next/router';
import { useGlobalStoreProvider } from '@providers/GlobalStoreProvider';
import {
    DIGICO_ASSESSMENT_ID_ALL,
    DIGICO_DEFAULT_ASSESSMENT,
} from '@lib/constants';
import type { NavigationItems } from '@digico/common/lib/NavigationItems';
import { useAuthProvider } from '@providers/AuthProvider';
import { useLanguageProvider } from '@providers/LanguageProvider';
import { CustomLink } from '@components/CustomLink';
import { SkillifyButton } from '@components/SkillifyButton';
import { CountryCodesDropDown } from '@components/DropDown';

interface NavigationProps {
    navigationItemsContent?: NavigationItems;
}

export const Navigation: React.FC<NavigationProps> = ({
    navigationItemsContent,
}: NavigationProps) => {
    const { signOut, isUserLoggedIn, isAboutSkillifyAccessible, authUser } =
        useAuthProvider();
    const { countryCode } = useLanguageProvider();
    const { setContextResults, setVisibleAreas, studentResultsData } =
        useGlobalStoreProvider();
    useGlobalStoreProvider();
    const [mobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false);
    const menuButton = useRef(null);
    const router = useRouter();
    const { pathname } = router;

    let partnerId =
        authUser?.partnerId && authUser?.partnerId !== ''
            ? authUser?.partnerId
            : DIGICO_ASSESSMENT_ID_ALL;

    const initialAssessmentId =
        authUser?.assessmentId && authUser?.assessmentId.trim() !== ''
            ? authUser?.assessmentId
            : DIGICO_DEFAULT_ASSESSMENT;

    console.info('authUser', authUser);

    const linkToAssessmentTool = `/assessment-tool/${partnerId}/${initialAssessmentId}?lang=${countryCode}`;

    if (typeof partnerId === 'object') {
        // There's a mismatch in type of partnerId, it is supposed to be a string but it is sometimes an object...
        partnerId = (partnerId as any).id;
    }

    useEffect(() => {
        if (mobileMenuOpened) {
            document.body.style.overflow = 'hidden';
        }
        if (!mobileMenuOpened) {
            const menu: any = menuButton.current;
            document.body.style.overflow = 'unset';
            if (menu && menu.checked) {
                menu.checked = false;
            }
        }
    }, [isUserLoggedIn, mobileMenuOpened]);

    const getAssessmentId = () => {
        if (studentResultsData && studentResultsData.length > 0)
            return studentResultsData[0].assessmentId;
        return '';
    };

    const assessmentId = getAssessmentId();

    const signOutCallback = useCallback(async () => {
        await signOut();
        Router.push('/').then(() => setMobileMenuOpened(false));
    }, [signOut]);

    return (
        <>
            <input
                ref={menuButton}
                tabIndex={0}
                className="menu-btn"
                type="checkbox"
                id="menu-btn"
                aria-label="navigation menu"
                onChange={() => setMobileMenuOpened(!mobileMenuOpened)}
            />
            <label className="menu-icon" htmlFor="menu-btn">
                <span className="nav-icon" />
            </label>
            <nav className="menu tw-my-1 tw-items-center tw-start-0 lg:tw-start-auto">
                <ul>
                    {isUserLoggedIn && assessmentId !== '' && (
                        <li className="nav-item">
                            <CustomLink
                                href={`/learning-content?partnerId=${partnerId}&assessmentId=${assessmentId}&scrollToCourses=true&view=summary`}
                                className="nav-item"
                                onClick={() => {
                                    setMobileMenuOpened(false);
                                    window.sessionStorage.clear();
                                    setContextResults([]);
                                    setVisibleAreas([]);
                                }}
                            >
                                {navigationItemsContent?.userDashboard ||
                                    'My Results'}
                            </CustomLink>
                        </li>
                    )}
                    {isUserLoggedIn && (
                        <li className="nav-item">
                            <CustomLink
                                href="/courses"
                                className="nav-item"
                                onClick={() => {
                                    setMobileMenuOpened(false);
                                    window.sessionStorage.clear();
                                    setContextResults([]);
                                    setVisibleAreas([]);
                                }}
                            >
                                {navigationItemsContent?.courses || 'Courses'}
                            </CustomLink>
                        </li>
                    )}
                    <li className="nav-item">
                        <CustomLink
                            href={linkToAssessmentTool}
                            className="nav-item"
                            target="assessment-tool"
                            onClick={() => {
                                setMobileMenuOpened(false);
                                window.sessionStorage.clear();
                            }}
                        >
                            {navigationItemsContent?.assessment || 'Assessment'}
                        </CustomLink>
                    </li>
                    {isAboutSkillifyAccessible && (
                        <li className="nav-item">
                            <CustomLink
                                href="/about-digcomp"
                                className="nav-item"
                                onClick={() => {
                                    setMobileMenuOpened(false);
                                }}
                            >
                                {navigationItemsContent?.aboutDigComp ||
                                    'About Skillify'}
                            </CustomLink>
                        </li>
                    )}
                </ul>
                <ul>
                    <li className="nav-item">
                        {!pathname.includes('student-portal') ? (
                            <SkillifyButton
                                className="tw-my-1 tw-flex tw-justify-center lg:tw-my-4"
                                onClick={() =>
                                    router
                                        .push(linkToAssessmentTool)
                                        .then(() => setMobileMenuOpened(false))
                                }
                                isRound
                            >
                                <span className="skillify-font tw-inline-block tw-text-base tw-font-medium tw-leading-5">
                                    {navigationItemsContent?.testMySkills ||
                                        'Test my skills'}
                                </span>
                            </SkillifyButton>
                        ) : (
                            <SkillifyButton
                                className="tw-my-1 tw-flex tw-justify-center lg:tw-my-4"
                                onClick={() =>
                                    router
                                        .push(linkToAssessmentTool)
                                        .then(() => {
                                            setMobileMenuOpened(false);
                                            window.sessionStorage.clear();
                                        })
                                }
                                isRound
                            >
                                <span className="skillify-font tw-inline-block tw-text-base tw-font-medium tw-leading-5">
                                    {navigationItemsContent?.startNewTest ||
                                        'Start new test'}
                                </span>
                            </SkillifyButton>
                        )}
                    </li>
                    {isUserLoggedIn ? (
                        <li className="nav-item">
                            <SkillifyButton
                                className="tw-my-1 tw-flex tw-justify-center lg:tw-my-4"
                                onClick={signOutCallback}
                                buttonType="secondary"
                                isRound
                            >
                                <span className="skillify-font tw-inline-block tw-text-base tw-font-medium tw-leading-5">
                                    {navigationItemsContent?.signOut ||
                                        'Sign out'}
                                </span>
                            </SkillifyButton>
                        </li>
                    ) : (
                        <li className="nav-item">
                            <SkillifyButton
                                className="tw-my-1 tw-flex tw-justify-center lg:tw-my-4"
                                onClick={() =>
                                    Router.push('/sign-in').then(() => {
                                        setMobileMenuOpened(false);
                                        setContextResults([]);
                                        setVisibleAreas([]);
                                    })
                                }
                                buttonType="secondary"
                                isRound
                            >
                                <span className="skillify-font tw-inline-block tw-text-base tw-font-medium tw-leading-5">
                                    {navigationItemsContent?.signIn ||
                                        'Sign in'}
                                </span>
                            </SkillifyButton>
                        </li>
                    )}

                    <li className="nav-item tw-my-0 lg:tw-my-2">
                        <CountryCodesDropDown />
                    </li>
                </ul>
            </nav>
        </>
    );
};
