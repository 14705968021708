//  FIXME:  These should be on an .env file!
//          We probably do not want to share this on the repo.

exports.DIGICO_ASSESSMENT_ID_ALL = 'g5P2JIev6yjBTRy5vhMo';
exports.DIGICO_DEFAULT_ASSESSMENT = '4PtySHyxKzEDpaomsTxI';
exports.DIGICO_ASSESSMENT_ID_INFO_DATA = 'UwWg3fqWn1uirxKpBlSd';
exports.DIGICO_ASSESSMENT_ID_COM_COL = 'bfRVCpjtHRq2lbBbEPwJ';
exports.DIGICO_ASSESSMENT_ID_CREATION = 'YOiBrpG0rFBVAc1tcdRL';
exports.DIGICO_ASSESSMENT_ID_SAFETY = 'SXXA7mteomoos11YEg1P';
exports.DIGICO_ASSESSMENT_ID_PROBLEM = 'KuBd7YRi3pnMDfYDWaYL\n';

// using this.VARIABLE_NAME gives an error in tests
// TypeError: Cannot read properties of undefined
exports.ALL_DIGICO_IDS = [
    'g5P2JIev6yjBTRy5vhMo',
    'UwWg3fqWn1uirxKpBlSd',
    'bfRVCpjtHRq2lbBbEPwJ',
    'YOiBrpG0rFBVAc1tcdRL',
    'SXXA7mteomoos11YEg1P',
    'KuBd7YRi3pnMDfYDWaYL',
];

exports.DIGCOMP_INFO_AREA_ID = 'UwWg3fqWn1uirxKpBlSd';
exports.DIGCOMP_COM_AND_COL_AREA_ID = 'bfRVCpjtHRq2lbBbEPwJ';
exports.DIGCOMP_DIGITAL_AREA_ID = 'YOiBrpG0rFBVAc1tcdRL';
exports.DIGCOMP_SAFETY_AREA_ID = 'SXXA7mteomoos11YEg1P';
exports.DIGCOMP_PROBLEM_AREA_ID = 'KuBd7YRi3pnMDfYDWaYL';

exports.PDF_CONTENT_ID = 'XTC6s8AjUcg0kIVHe7RE';

exports.API_STATIC_FILES = 'https://firebasestorage.googleapis.com/v0/b/';

exports.PARENT_URL = 'parent-url';

exports.CONTACT = 'hello@digico.global';

exports.DIGICO_GLOBAL = 'https://digico.global/';

exports.CUSTOM_HEADER_PATHS = ['/learning-content'];

exports.CUSTOM_FOOTER_PATHS = ['/assessment-tool/[partnerId]'];

exports.EMAIL_API_KEY =
    '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';

exports.PARTNER_PATH = 'partner';

exports.LEARNING_CONTENT_PATH =
    'https://digico-global-web.web.app/learning-content';

exports.ANALYTICS_NAMES = {
    assessmentArea: 'Assessment Area',
};
