import { Image } from '@components/Image';
import type { Languages } from '@digico/common/lib';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useLanguageProvider } from '@providers/LanguageProvider';
import { getImageUrl } from '@lib/helper/getImageUrl';
import { useClickedOutsideOfComponent } from '@lib/hooks/useClickedOutsideOfComponent';
import styles from './CountryCode.module.css';

export const CountryCodesDropDown = () => {
    const router = useRouter();
    const [dropdownActive, setDropdown] = useState(false);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const isClickedOutsideOfComponent =
        useClickedOutsideOfComponent(wrapperRef);

    useEffect(() => {
        if (isClickedOutsideOfComponent) setDropdown(false);
    }, [isClickedOutsideOfComponent]);

    const { countryCode, languages } = useLanguageProvider();

    const getFlag = (langCode: string) => {
        const lang = languages?.find(
            (l: Languages.Language) => l.code === langCode,
        );
        return lang?.flag;
    };

    const chooseLanguage = (lang: Languages.Language) => {
        setDropdown(false);
        localStorage.setItem('code', lang.code ?? 'en');
        window.location.replace(
            window.location.href.replace(/lang=[A-Za-z]+/, `lang=${lang.code}`),
        );
    };

    const sortLanguages = (a: Languages.Language, b: Languages.Language) => {
        const langA = a.code?.toLowerCase() ?? '';
        const langB = b.code?.toLowerCase() ?? '';
        // Make english first item in list
        if (langA === 'en') return -1;
        if (langB === 'en') return 1;

        if (langA < langB) return -1;
        if (langB < langA) return 1;
        return 0;
    };

    return (
        <div
            ref={wrapperRef}
            className="tw-flex tw-flex-col tw-items-center tw-justify-center"
        >
            {countryCode && (
                <div
                    role="option"
                    tabIndex={0}
                    aria-selected
                    className="tw-flex tw-flex-row tw-items-center tw-gap-5"
                    onKeyPress={() => setDropdown(!dropdownActive)}
                    onClick={() => setDropdown(!dropdownActive)}
                >
                    <Image
                        className="tw-h-5 tw-w-8"
                        src={getImageUrl(getFlag(countryCode))}
                    />

                    <span className="tw-cursor-pointer tw-select-none">
                        {countryCode.toUpperCase()}
                    </span>
                    <span className="tw-w-[20px] tw-cursor-pointer tw-select-none">
                        {String.fromCharCode(dropdownActive ? 9650 : 9660)}
                    </span>
                </div>
            )}

            <div
                style={{
                    position: router.asPath.includes('assessment-tool')
                        ? 'absolute'
                        : 'unset',
                }}
            >
                <ul
                    className={`tw-z-[99] tw-mt-5 tw-flex tw-max-h-[10.75rem] tw-w-[20rem] tw-select-none tw-select-none tw-flex-col tw-overflow-y-auto tw-overflow-y-auto tw-rounded-[1.25rem] tw-border-2 tw-border-solid tw-border-blue-700 tw-bg-white tw-px-1 tw-py-2.5 lg:tw-absolute lg:tw-max-h-[21.875rem] lg:tw-end-8 ${
                        router.asPath.includes('assessment-tool')
                            ? `tw-absolute tw-w-[16rem] ltr:-tw-right-[58px] rtl:-tw-left-[58px] xs:tw-w-[20rem] lg:ltr:-tw-right-[58px] lg:rtl:-tw-left-[58px] ${styles.dropdownAssessmentTool}`
                            : styles.dropdown
                    } ${dropdownActive ? 'tw-flex' : 'tw-hidden'}`}
                >
                    {languages
                        ?.filter(
                            (language: Languages.Language) =>
                                language?.fullyTranslated,
                        )
                        .sort(sortLanguages)
                        .map((lang: Languages.Language) => (
                            <li
                                className="tw-my-2.5 tw-mx-5 tw-flex tw-cursor-pointer tw-flex-row tw-items-center"
                                role="option"
                                tabIndex={0}
                                aria-selected
                                key={lang.code}
                                onKeyPress={() => chooseLanguage(lang)}
                                onClick={() => chooseLanguage(lang)}
                            >
                                <Image
                                    className="tw-h-5 tw-w-8"
                                    src={getImageUrl(lang.flag)}
                                />

                                <div className="tw-ms-5" />

                                <span>
                                    {lang.code?.toUpperCase()} - {lang.name}
                                </span>
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
};
