import { useEffect, useState } from 'react';
import { DynamicPagesPath } from '@lib/firebase/firestorePaths';
import { useDocument } from 'react-firebase-hooks/firestore';
import { doc, FirestoreError, getFirestore } from 'firebase/firestore';

type DynamicsPagesPath =
    | 'aboutDigComp'
    | 'footer'
    | 'forgotPassword'
    | 'learningContent'
    | 'navigationItems'
    | 'privacyPolicy'
    | 'signIn'
    | 'signUp'
    | 'studentPortal'
    | 'courses';

export const useDynamicPagesContent = <T extends {}>(
    countryCode: string,
    pathName: DynamicsPagesPath,
): [T | undefined, boolean, FirestoreError | undefined] => {
    const [content, setContent] = useState<T>();
    const path =
        countryCode === 'en'
            ? `${DynamicPagesPath}/${pathName}`
            : `${DynamicPagesPath}/${pathName}/locales/${countryCode}`;

    const [documentSnapshot, isLoading, error] = useDocument(
        doc(getFirestore(), path),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        },
    );

    useEffect(() => {
        if (documentSnapshot) {
            setContent(documentSnapshot.data()?.dynamicPage?.[0]?.value as T);
        }
    }, [documentSnapshot]);

    return [content, isLoading, error];
};
