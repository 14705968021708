import { RefObject, useEffect, useState } from 'react';

export const useClickedOutsideOfComponent = (
    ref: RefObject<HTMLElement> | null,
) => {
    const [isOutsideComponent, setIsOutsideComponent] = useState(false);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!ref?.current?.contains(event.target as Node)) {
                setIsOutsideComponent(true);
            } else setIsOutsideComponent(false);
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return isOutsideComponent;
};
