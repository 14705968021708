import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import * as FirebaseAuth from '@firebase/auth';
import { GoogleAuthProvider } from '@firebase/auth';
import { firebaseApp } from '@lib/firebase/firebase';
import { servicesURL } from '@lib/firebase/config';
import { addDoc, collection, updateDoc } from 'firebase/firestore';
import { db } from '@lib/firebase/firestore-crud';
import { StudentPath } from '@lib/firebase/firestorePaths';
import {
    AuthUserContextType,
    DigicoUser,
    GoogleSignupValues,
    IsNewStudent,
    ManualSignupValues,
} from '@lib/types';
import { useSendSignupMail } from '@lib/hooks/useSendSignUpEmail';
import { useLanguageProvider } from '@providers/LanguageProvider';
import { useIsLoading } from '@lib/hooks/useIsLoading';
import { doc, getDoc } from '@firebase/firestore';
import _ from 'lodash';
import type { Student } from '@digico/common/lib/Student';
import { useRouter } from 'next/router';
import { getData } from '../services/api';

const authUserContext = createContext<AuthUserContextType | undefined>(
    undefined,
);

const generateFullName = (firstName: string, lastName: string) => {
    const fullName = `${firstName} ${lastName}`;
    return fullName.trim();
};

const AuthProvider: React.FunctionComponent = ({ children }) => {
    const router = useRouter();
    const { countryCode } = useLanguageProvider();
    const auth = FirebaseAuth.getAuth(firebaseApp);
    const [authUser, setAuthUser] = useState<DigicoUser | null>(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(
        auth.currentUser !== null,
    );
    const [loading, setLoading] = useState(true);
    const [isAboutSkillifyAccessible, setIsAboutSkillifyAccessible] =
        useState<boolean>(true);
    const sendSignupEmail = useSendSignupMail(countryCode);
    const isLoading = useIsLoading([loading]);
    const formatAuthUser = useCallback(
        async (
            user: FirebaseAuth.User,
            values?: ManualSignupValues | GoogleSignupValues,
        ): Promise<[DigicoUser, IsNewStudent]> => {
            const { exists, id } = await getData(
                `${servicesURL}service-checkIfStudentEmailExists`,
                'POST',
                {
                    email: user.email,
                },
            );

            let studentId: string | undefined = id;
            let fullName: string | null = null;
            let isNewStudent = false;
            const { partnerId, assessmentId } = router.query;
            if (values?.firstName || values?.lastName) {
                fullName = generateFullName(
                    values.firstName ?? '',
                    values.lastName ?? '',
                );
            }
            if (!exists && values) {
                const res = await addDoc(collection(db, StudentPath), {
                    name: user.displayName ?? fullName,
                    emailAddress: user.email,
                    age: parseInt(values.age, 10),
                    assessmentId,
                    ..._.omit(values, [
                        'password',
                        'confirmPassword',
                        'emailAddress',
                        'age',
                    ]),
                });
                if (user.email)
                    await sendSignupEmail(values.firstName, user.email);
                studentId = res.id;
                isNewStudent = true;
                await updateDoc(res, { partnerId });
            }

            if (studentId) {
                const docRef = doc(db, StudentPath, studentId);
                const studentValuesSnapshot = await getDoc(docRef);
                const studentValues = studentValuesSnapshot.data() as
                    | Student
                    | undefined;

                if (studentValues)
                    return [
                        {
                            partnerId:
                                (studentValues.partnerId as string) ?? '',
                            assessmentId:
                                (studentValues.assessmentId as string) ?? '',
                            displayName:
                                studentValues.name ??
                                generateFullName(
                                    studentValues.firstName ?? '',
                                    studentValues.lastName ?? '',
                                ),
                            studentId,
                            emailAddress: studentValues.emailAddress,
                            age: studentValues.age?.toString(),
                            benefits: studentValues.benefits,
                            country: studentValues.country,
                            disability: studentValues.disability,
                            education: studentValues.education,
                            employment: studentValues.employment,
                            firstName: studentValues.firstName,
                            gender: studentValues.gender,
                            lastName: studentValues.lastName,
                            residency: studentValues.residency,
                        },
                        isNewStudent,
                    ];
            }

            return [
                {
                    partnerId: (partnerId as string) ?? '',
                    assessmentId: (assessmentId as string) ?? '',
                    displayName: user.displayName ?? fullName,
                    studentId,
                    emailAddress: user.email,
                },
                isNewStudent,
            ];
        },
        [sendSignupEmail],
    );

    const authStateChanged = useCallback(
        async (user: FirebaseAuth.User | null) => {
            if (!user) {
                setIsUserLoggedIn(false);
                setLoading(false);
                return;
            }
            setIsUserLoggedIn(true);
            setLoading(true);

            const [formattedUser] = await formatAuthUser(user);
            setIsAboutSkillifyAccessible(true);
            setAuthUser(formattedUser);
            setLoading(false);
        },
        [formatAuthUser],
    );

    const clear = () => {
        setAuthUser(null);
        setLoading(false);
    };

    const signInWithEmailAndPassword = useCallback(
        async (email: string, password: string) => {
            const userCredential =
                await FirebaseAuth.signInWithEmailAndPassword(
                    auth,
                    email,
                    password,
                );
            const [user] = await formatAuthUser(userCredential.user);
            return user;
        },
        [auth, formatAuthUser],
    );

    const createUserWithEmailAndPassword = useCallback(
        async (signUpValues: ManualSignupValues) => {
            const { password, emailAddress } = signUpValues;
            if (!password || !emailAddress) {
                throw new Error('Email or Password undefined');
            }
            localStorage.setItem('creatingUser', 'true');
            const userCredential =
                await FirebaseAuth.createUserWithEmailAndPassword(
                    auth,
                    emailAddress,
                    password,
                );

            return formatAuthUser(userCredential.user, signUpValues);
        },
        [auth, formatAuthUser],
    );

    const signOut = useCallback(
        () => FirebaseAuth.signOut(auth).then(clear),
        [auth],
    );

    const signInWithGoogle = useCallback(
        async (values?: GoogleSignupValues) => {
            const userCredential = await FirebaseAuth.signInWithPopup(
                auth,
                new GoogleAuthProvider(),
            );
            if (values) return formatAuthUser(userCredential.user, values);

            const { exists } = await getData(
                `${servicesURL}service-checkIfStudentEmailExists`,
                'POST',
                {
                    email: userCredential.user.email,
                },
            );

            if (!exists) {
                await signOut();
                router.push('/sign-up');
                throw Error();
            }

            return formatAuthUser(userCredential.user);
        },
        [auth, formatAuthUser, router, signOut],
    );

    const sendPasswordResetEmail = useCallback(
        (email: string, actionCodeSettings: FirebaseAuth.ActionCodeSettings) =>
            FirebaseAuth.sendPasswordResetEmail(
                auth,
                email,
                actionCodeSettings,
            ),
        [auth],
    );

    useEffect(() => {
        if (!isAboutSkillifyAccessible)
            localStorage.setItem(
                'isAboutSkillifyAccessible',
                `${isAboutSkillifyAccessible}`,
            );
    }, [isAboutSkillifyAccessible]);

    useEffect(() => {
        const isAccessible = localStorage.getItem('isAboutSkillifyAccessible');
        if (isAccessible) {
            setIsAboutSkillifyAccessible(JSON.parse(isAccessible));
        }

        return () => {
            setIsAboutSkillifyAccessible(true);
        };
    }, []);

    useEffect(() => {
        const storageEventHandler = (event: StorageEvent) => {
            if (event.key === 'isAboutSkillifyAccessible') {
                event.newValue
                    ? setIsAboutSkillifyAccessible(JSON.parse(event.newValue))
                    : setIsAboutSkillifyAccessible(true);
            }
        };
        window.addEventListener('storage', storageEventHandler);
        return () => {
            window.removeEventListener('storage', storageEventHandler);
        };
    }, []);

    useEffect(() => {
        const unsubscribe = FirebaseAuth.onAuthStateChanged(
            auth,
            authStateChanged,
        );
        return () => unsubscribe();
    }, [auth, authStateChanged]);

    const authUserContextValue = useMemo<AuthUserContextType>(
        () => ({
            authUser,
            isLoading,
            isUserLoggedIn,
            signInWithEmailAndPassword,
            signInWithGoogle,
            createUserWithEmailAndPassword,
            sendPasswordResetEmail,
            signOut,
            isAboutSkillifyAccessible,
            setIsAboutSkillifyAccessible,
        }),
        [
            authUser,
            isLoading,
            isUserLoggedIn,
            signInWithEmailAndPassword,
            signInWithGoogle,
            createUserWithEmailAndPassword,
            sendPasswordResetEmail,
            signOut,
            isAboutSkillifyAccessible,
            setIsAboutSkillifyAccessible,
        ],
    );

    return (
        <authUserContext.Provider value={authUserContextValue}>
            {children}
        </authUserContext.Provider>
    );
};

export default AuthProvider;

export const useAuthProvider = () => {
    const context = useContext(authUserContext);
    if (!context) {
        throw new Error('useAuthProvider must be used within a AuthProvider');
    }
    return context;
};
