import { Competency, Score } from '@lib/types';
import { mapScore } from '@lib/helper/mapScore';
import { buildAreaPercentage } from '@lib/helper/buildAreaPercentage';

/**
 * Grabs the scores from a given competency and resolves them into a qualitative result
 *
 * @param {Object<any>} competencies The list of competencies
 * @returns {Score} The qualitative score
 */
export const buildAreaScore = (competencies: Competency[]): Score => {
    const result = buildAreaPercentage(competencies);
    return mapScore(result);
};
